import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { kebabCase } from 'lodash';
import { CountryGlobal } from 'types';

import { EvaluationStakeHolder } from 'components/CompetitiveLandscape/EvaluationStakeHolder';
import { Loading } from 'components/Loading';
import { Navbar } from 'components/Navbar';
import { Observations } from 'components/Observations';
import { StakeholderDefinitionSummaryModal } from 'components/StakeholderDefinitionSummaryModal';
import useHandleRegionDropdown from 'hooks/useHandleRegionDropdown';

import { CountriesDropdownSmall } from 'components/shared/CountriesDropdownSmall';
import {
  BodyNormal,
  ButtonPill,
  StepHeaderBar,
  Subtitle2,
} from 'components/shared';
import { TooltipWrapper } from 'components/shared/ButtonPill';
import {
  StakeholderDefinitions,
  Wrapper as StakeholderDefinitionsWrapper,
} from 'components/shared/StakeholderDefinitions';
import { colors } from 'constants/colors';
import { polling } from 'constants/index';
import { zIndex } from 'constants/zIndex';
import { useAuthContext } from 'contexts/AuthContext';
import { useStakeholderContext } from 'contexts/StakeholderContext';
import {
  CompetitiveLandscapeRatingFragment,
  CompetitorFragment,
  Sort,
  Stakeholder,
  Step,
  useCompetitorsQuery,
  usePostItGroupsWithCardsQuery,
  useStakeholderDefinitionsQuery,
} from 'data/graphql/generated';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { useQuery } from 'hooks/useQuery';
import { PostItGroupAndCards } from 'types';
import { device } from 'utils/breakpoints';
import { stakeholderAliases } from 'utils/getStakeholderAlias';
import buildStrategyURL from '../utils/buildStrategyURL';
import { PostItsEmpty } from 'components/PostItsEmpty';
import {  BodySmall } from 'components/shared/TextStyles';



const ComponentWrapper = styled.div``;

const StepHeader = styled(StepHeaderBar)`
  display: block;
  padding: 18px 20px 0 20px;
  border-bottom: 0.5px solid ${colors.greyMedium};

  @media ${device.tabletMax} {
    margin-top: 160px;
    display: block;
  }

  ${TooltipWrapper} {
    position: relative;
  }

  ${StakeholderDefinitionsWrapper} {
    margin-bottom: 15px;
  }
`;

const StyledPostItsEmpty = styled(PostItsEmpty)`
  background: var(--white-50, rgba(255, 255, 255, 0.50));
  height: 100%;
  @media ${device.tabletMin} {
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }

  @media ${device.mobile} {
    padding-bottom: 88px;
    height: auto;
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }
`;
const HeaderContainer = styled.div``;

export const ChooseCompetitorsBar = styled.div`
  min-height: 115px;
  padding: 15px;
  position: absolute;
  border-bottom: 0.5px solid ${colors.greyMedium};
  z-index: ${zIndex.chooseCompetitorsBar};
  background: ${colors.white};
  width: 100%;

  ${TooltipWrapper} {
    display: inline-grid;
    margin-right: 20px;
  }
`;


export const Divider = styled.div`
  height: 30px;
  width: 1px;
  background: ${colors.white50};
  margin-right: 15px;
`;

export const EvaluateButtonContainerDesktop = styled.div`
  margin-top: 20px;
  display: flex;
  margin-left: 5px;

  ${Divider} {
    margin-right: 10px;
  }

  @media ${device.desktopMin} {
    margin-top: 0;
  }
`;

export const PageNavChildren = styled.div`
  display: flex;
  justify-content: space-center;
  order: -1;
  flex-grow: 1;
  margin-left: 15px;
`;

const NavbarContent = styled.div`
  @media screen and (min-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    alignitems: center;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  stakeholderDefinitionId: string;
  competitorId: string;
  region: string;
}

export const CompetitiveLandscapeResult = () => {
  const {
    drugId,
    strategyId,
    stakeholderDefinitionId,
    competitorId,
    stakeholder: stakeholderParam,
    region
  }: URLParams = useParams();

  const [stakeholder,] = useStakeholderContext(
    stakeholderParam && (stakeholderParam as any) !== 'undefined'
      ? stakeholderParam
      : 'Patient'
  );

  const [{ user }] = useAuthContext();

  const history = useHistory();
  const query = useQuery();

  const {
    selectedRegion,
    countryDropdownActive,
    setCountryDropdownActive,
    countryDropDownRef,
    uniqueRegions
  } = useHandleRegionDropdown({ region, user, strategyId: +strategyId });
  // const uniqueRegions = getUniqueRegions(strategy?.users || [], false);
  const regionsExcludingGlobal = uniqueRegions.slice().sort();

  // useEffect(() => {
  //   if (!stakeholderParam && stakeholder) {
  //     history.replace(
  //       `/d/${drugId}/strategy/${strategyId}/1_2/${
  //         stakeholder || Stakeholder.Patient
  //       }`
  //     );
  //   }
  // }, [drugId, history, stakeholder, stakeholderParam, strategyId]);

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showEvaluation,] = useState(
    query.get('evaluating') === 'true'
  );
  const [showDistribution, setShowDistribution] = useState(true);

  const competitorsQueryVars = {
    where: { strategyId: +strategyId },
  };

  const {
    data: strategyCompetitorsData,
    loading: competitorsLoading,
    error: competitorsError,
    startPolling: startPollingCompetitors,
    stopPolling: stopPollingCompetitors,
  } = useCompetitorsQuery({
    variables: competitorsQueryVars,
  });

  const {
    items: groupItems,
    loading: groupsLoading,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        // stakeholder,
        step: Step.Competitivelandscape,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Competitivelandscape,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        // stakeholder,
        stakeholderDefinitionId: Number(stakeholderDefinitionId),
        // competitorId: Number(competitorId),
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keystakeholders,
      // stakeholder,
      stakeholderDefinitionId: Number(stakeholderDefinitionId),
      // competitorId: Number(competitorId),
    }
  );

  // get "What are the outcomes that matter?" cards to sort the groups
  const {
    data: groupsWithCardsData,
    error: groupsWithCardsError,
    loading: groupsWithCardsLoading,
  } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        // stakeholder,
        step: Step.Keystakeholders,
        // title: `What are the outcomes that matter to ${mapStakeholderEnumToPostItGroupTitle[stakeholder]}?`,
        preset: true,
      },
    },
    fetchPolicy: 'no-cache',
  });

  
  const addCardsToGroups = useMemo(
    () => {
      const uniqueTitles = new Set<string>();
  
      return groupItems.reduce((acc, val) => {
        // Filter cardItems by postItGroupId and include === true
        const filteredCards = cardItems
          .filter((c) => {
            return c.title === val.title && c.include === true; // Check for postItGroupId and include
          })
          .sort(({ pos: a }, { pos: b }) => b - a); // Sort by 'pos'
  
  
        // If there are no filtered cards, skip this group
        if (filteredCards.length === 0) return acc;
  
        // Check if the group title has already been added
        if (!uniqueTitles.has(val.title)) {
          uniqueTitles.add(val.title); // Add title to the set to avoid duplicates
  
          return [
            ...acc,
            {
              ...val,
              cards: filteredCards, // Assign filtered cards to the group
            },
          ];
        }
  
        // Skip groups with duplicate titles
        return acc;
      }, [] as PostItGroupAndCards[]);
    },
    [cardItems, groupItems] // Dependencies
  );
  

  const addCardsToGroups2 = useMemo(
    () =>
      groupItems.reduce((acc, val) => {
        // Check if stakeholder is not null before proceeding
        if (val.stakeholder !== null) {
          return [
            ...acc,
            {
              ...val,
              cards:
                cardItems
                  .filter((c) => c.postItGroupId === val.id)
                  .sort(({ pos: a }, { pos: b }) => b - a) || [],
            },
          ];
        }
        return acc; // If stakeholder is null, return the accumulator without changes
      }, [] as PostItGroupAndCards[]),
    [cardItems, groupItems]
  );
  

  // const cardsByAlignmentOrder = useMemo(
  //   () =>
  //     (whatAreTheOutcomesGroup?.cards || []).sort((a, b) => {
  //       return (
  //         (b?.collaboration?.alignmentCount || 0) -
  //         (a?.collaboration?.alignmentCount || 0)
  //       );
  //     }),
  //   [whatAreTheOutcomesGroup?.cards]
  // );
  
  // Sort all the cards by alignmentCount (if needed)

  const groups = addCardsToGroups;
  const groups2 = addCardsToGroups2;

  const {
    data: stakeholderDefinitions,
    error: stakeholderDefinitionsError,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    variables: {
      orderBy: { createdAt: Sort.Asc },
      where: {
        stakeholder: Stakeholder.Patient,
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(polling.default);
    startPollingCompetitors(polling.default);
    return () => {
      stopPolling();
      stopPollingCompetitors();
    };
  }, [
    startPolling,
    stopPolling,
    startPollingCompetitors,
    stopPollingCompetitors,
  ]);

  const validStakeholderDefinitions = useMemo(
    () =>
      stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
        (definition) => definition.title
      ) || [],
    [stakeholderDefinitions]
  );

  const selectedStakeholderDefinition = useMemo(
    () =>
      validStakeholderDefinitions?.find(
        (s) => s.id === +stakeholderDefinitionId
      ),

    [validStakeholderDefinitions, stakeholderDefinitionId]
  );

  const selectedCompetitor = useMemo(
    () =>
      strategyCompetitorsData?.competitors?.items.find(
        (c) => c.id === +competitorId
      ),
    [competitorId, strategyCompetitorsData?.competitors?.items]
  );

  const firstStakeholderDefinition = validStakeholderDefinitions[0];


  const [activeCompetitors, setActiveCompetitors] = useState<
    CompetitorFragment[]
  >([]);

  useEffect(() => {
    if (strategyCompetitorsData?.competitors?.items) {
      const stakeholderCompetitorIds =
        selectedStakeholderDefinition?.CompetitorDetails?.filter(
          (cd) => cd?.stakeholder === stakeholder
        )?.map((cd) => cd?.competitorId) || [];

      setActiveCompetitors(
        (strategyCompetitorsData?.competitors?.items || [])
          .filter(
            (c) =>
              c?.CompetitorDetails?.some(
                (detail) => detail?.stakeholder === stakeholder
              ) && stakeholderCompetitorIds.includes(c.id)
          )
          .slice()
          .sort((a, b) => a.title.localeCompare(b.title)) || []
      );
    }
  }, [
    strategyCompetitorsData?.competitors?.items,
    stakeholder,
    selectedStakeholderDefinition,
  ]);

  useEffect(() => {
    if (stakeholderDefinitionsLoading) return;

    
    // history.replace(url + queryParam);
  }, [
    activeCompetitors,
    competitorId,
    drugId,
    firstStakeholderDefinition?.id,
    history,
    selectedCompetitor?.id,
    showEvaluation,
    stakeholder,
    stakeholderDefinitionId,
    stakeholderDefinitionsLoading,
    strategyId,
  ]);


  const hasStakeholderError =
    !stakeholderDefinitionsLoading && validStakeholderDefinitions.length === 0;

  const [, setRatings] = useState<CompetitiveLandscapeRatingFragment[]>(
    []
  );

  const targetPatientError = JSON.stringify(stakeholderDefinitionsError) || '';

  const hasNoTargetPatientsError = targetPatientError.includes(
    'NO_TARGET_PATIENTS'
  );
  const hasStakeholderNotIdentifiedError = targetPatientError.includes(
    'STAKEHOLDER_NOT_IDENTIFIED'
  );


  const dataIsLoading =
    cardsLoading ||
    groupsLoading ||
    stakeholderDefinitionsLoading ||
    groupsWithCardsLoading ||
    competitorsLoading;

  const dataMissing =
    !groupItems ||
    !cardItems ||
    !groupsWithCardsData ||
    !strategyCompetitorsData;

  const dataErrors = [
    groupsError || cardsError || groupsWithCardsError,
    competitorsError,
  ];

  const showingErrorPage =
    dataIsLoading || dataMissing || dataErrors.some((error) => !!error);

  useEffect(() => {
    if (groupsLoading || showingErrorPage) return;

    setRatings(
      groups
        .map((group) => {
          const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];

          return competitiveLandscapeRating.find(
            (rating) =>
              rating.stakeholderDefinitionId === +stakeholderDefinitionId &&
              rating.competitorId === +competitorId &&
              rating.user?.id === user?.id
          );
        })
        //For some annoying reason TS doesn't realise it's filtering out undefined
        //https://stackoverflow.com/questions/43010737/way-to-tell-typescript-compiler-array-prototype-filter-removes-certain-types-fro/54317362
        //T extends unknown is just syntax that allows me to use arrow functions for generics
        .filter(<T extends unknown>(val: T | undefined): val is T => {
          return !!val;
        })
    );
    //the groups variable should probably be memoized, but to prevent breaking anything, I am updating based on the initial data fetch instead as that's what matters
  }, [
    groupsLoading,
    groupItems,
    groupsWithCardsData,
    stakeholderDefinitionId,
    competitorId,
    user,
    groups,
    showingErrorPage,
  ]);

  return (
    <ComponentWrapper>
      {!!showSummaryModal && (
        <StakeholderDefinitionSummaryModal
          strategyId={Number(strategyId)}
          drugId={Number(drugId)}
          stakeholderDefId={selectedStakeholderDefinition?.id}
          handleClose={() => setShowSummaryModal(false)}
          visible={showSummaryModal}
        />
      )}
      <Navbar
        title="Competitive Landscape"
        prev={{
          title: 'Key Stakeholder Analysis',
          url: buildStrategyURL(String(drugId), String(strategyId), '1_1'),
        }}
        next={{
          title: 'Patient Journey',
          url: buildStrategyURL(String(drugId), String(strategyId), '1_3'),
        }}
        stepNumber="1.2"
      >
        <NavbarContent>
          <div style={{display: 'flex', gap: 10}}>
          {/* <StakeHolderTabs
            stakeholder={stakeholder}
            setStakeholder={(stakeholder) => {
              history.push(
                `/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}`
              );
              setStakeholder(stakeholder);
            }}
          /> */}

            <ButtonPill
              iconName="Arrow-left"
              text={'| Results'}
              onClick={()=>
                history.push(
                  `/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}/${stakeholderDefinitionId}`
                )
              }
              color={colors.white}
            />

            <CountriesDropdownSmall
              country={selectedRegion}
              active={countryDropdownActive}
              onClick={() =>
                setCountryDropdownActive(!countryDropdownActive)
              }
              className="clar"                  
              viewOnly={false}
              // hideCountryName={width < SMALLEST_MOBILE_WIDTH}
              largeIcon
              globalOptionText={'Global'}
              dropDownRef={countryDropDownRef}
              showDropdown={countryDropdownActive}
              setShowDropdown={setCountryDropdownActive}
              setCountry={(country) => {
                let baseURL = `/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}/result/${
                  stakeholderDefinitionId || 0
                }`;
                baseURL += `/${kebabCase(country as CountryGlobal)}`;
                history.replace(baseURL);
              }}
              allCountries={regionsExcludingGlobal}
            />
          </div>

        </NavbarContent>

        <Observations
          stakeholder={stakeholder}
          step={Step.Competitivelandscape}
        />
      </Navbar>

      <HeaderContainer>
        {!hasStakeholderError &&
          !hasNoTargetPatientsError &&
          !hasStakeholderNotIdentifiedError && (
            <StepHeader>
              {stakeholderDefinitionsLoading ? (
                <Loading style={{ width: 80, marginTop: 15 }} />
              ) : (
                <>
                  <Subtitle2 style={{ marginBottom: '5px' }}>
                    {showEvaluation
                      ? 'Evaluate the competitive landscape for '
                      : 'Assess the competitive landscape for '}
                    {stakeholderAliases[stakeholder]
                      ? stakeholderAliases[stakeholder].plural.toLowerCase()
                      : ''}
                  </Subtitle2>
                  <BodyNormal
                    color={colors.greyDark}
                    style={{ marginBottom: '15px' }}
                  >
                    {showEvaluation
                      ? 'Higher scores represent stronger performance. Leads can decide which outcomes provide an opportunity for differentiation.'
                      : 'Choose competitors, consider their advantages and disadvantages, and rate their strength against each outcome.'}
                  </BodyNormal>

                  <StakeholderDefinitions
                    stakeholderDefinitions={validStakeholderDefinitions || []}
                    selectedStakeholderDefinition={
                      selectedStakeholderDefinition
                    }
                    result={true}
                    stakeholder={stakeholder}
                    drugId={drugId}
                    strategyId={strategyId}
                    showEvaluation={showEvaluation}
                    // competitorId={competitorId}
                    page="1_2"
                    viewSummary={() => setShowSummaryModal(true)}
                  />
                </>
              )}
            </StepHeader>
          )}
        </HeaderContainer> 

        {groups.length && groups2.length ?
          <EvaluationStakeHolder
            groups={groups}
            groups2={groups2}
            strategyId={+strategyId}
            drugId={+drugId}
            selectedRegion={selectedRegion}
            // stakeholder={'Patient'}
            stakeholderDefinitionId={+stakeholderDefinitionId}
            competitorId={+competitorId}
            competitors={activeCompetitors || []}
            showDistribution={showDistribution}
            setShowDistribution={setShowDistribution}
          />
        :  <div style={{ height: '100%', marginTop: 150}}>
        <StyledPostItsEmpty
          title="Nothing to evaluate"
        >
          <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
            Work can only begin when a Lead has added the target population, outcomes and competitors
          </BodySmall>
          <div style={{padding: 50}}></div>
        </StyledPostItsEmpty>
      </div> }
    </ComponentWrapper>
  );
};
