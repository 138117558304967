import React from 'react';
import styled from 'styled-components';

import { Stakeholder, StakeholderDefinitionFragment } from 'data/graphql/generated';
import { SubMenu, TabGroup } from 'components/shared';
import { device } from 'utils/breakpoints';
import { Patients } from '../components/Patients';
import { Outcomes } from './Outcomes';
import { useHistory, useParams } from 'react-router-dom';

const StepBarsWrapper = styled.div`
  width: 100%;
  display: flex;

  > * + * {
    margin-left: 15px;
  }
`;

const TabGroupWrapper = styled.div`
  margin-top: 0px;
  @media ${device.tabletMax} {
    margin-top: 20px;
  }
`;

interface Props {
  isLead: boolean;
  stakeholder: Stakeholder;
  setStakeholder(stakeholder: Stakeholder): void;
  refetchGroupsWithCards(): void;
  refetchStakeholderDefinitions(): void;
  selectedStakeholderDefinition?: StakeholderDefinitionFragment;
}

export const StakeHolderMenu: React.FC<Props> = ({
  stakeholder,
  setStakeholder,
  isLead,
  refetchStakeholderDefinitions,
  refetchGroupsWithCards,
  selectedStakeholderDefinition,
}) => {

  const { drugId, strategyId, stakeholderDefinitionId } = useParams<{
    drugId: string;
    strategyId: string;
    stakeholderDefinitionId: string;
  }>();
  const history = useHistory();

  return (
    <TabGroupWrapper>
      <TabGroup componentName="StepTab">
        <StepBarsWrapper>
          {isLead && 
            <Patients refetchStakeholderDefinitions={refetchStakeholderDefinitions} />
          }
          <Outcomes refetchGroupsWithCards={refetchGroupsWithCards} selectedStakeholderDefinition={selectedStakeholderDefinition}/>
          <SubMenu
            onClick={()=>{
              history.push(
                `/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}/result/${stakeholderDefinitionId}`
              )
            }
            }
            icon="Lightning"
            text="Results"
          />
        </StepBarsWrapper>
      </TabGroup>
    </TabGroupWrapper>
  );
};
