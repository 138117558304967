import { BodySmall, ChartButton, LineGraph } from 'components/shared';
import { colors } from 'constants/index';
import {
  CompetitiveLandscapeRatingFragment,
  CompetitorFragment,
  PostItGroupFragment,
} from 'data/graphql/generated';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  CompetitorImage,
  CompetitorTitle,
  CompetitorTitleContainer,
  CompetitorTitleWrapper,
  createScoreDistribution,
} from './Evaluation';
import { average } from 'utils/average';
import { GraphScroller } from './GraphScroller';
import { throttle } from 'lodash';
import { ScorePill } from 'components/shared/ScorePill';
import { DistributionModalValues } from './DistributionModal';
import { TreatmentIcon } from './TreatmentIcon';
import { isSafari } from 'utils/isSafari';

const MOBILE_GRAPHS_CLASSNAME = 'Mobile-graphs-container';

const CompetitorGraphsScrollWrapper = styled.div<{ height?: string }>`
  display: flex;
  gap: 2px;
  height: ${({ height }) => (height ? height : '125px')};
`;

const GraphAreaWrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : '125px')};

  background: ${colors.white};
  padding: 10px;
  border-radius: 0px 5px 5px 0px;

  @media (max-width: 714px) {
    border-radius: 0px 0px 5px 5px;
    height: auto;
  }
`;

const CompetitorsGraphAreaWrapper = styled.div<{
  isMobile: boolean;
  height?: string;
}>`
  min-width: 240px;
  width: 240px;
  height: 100%;
  padding: 5px;
  flex: ${({ isMobile }) => (isMobile ? 1 : 'none')};

  background: ${colors.white};

  @media (max-width: 714px) {
    border-radius: 0px 0px 5px 5px;
    height: auto;
  }
`;

const StyledLineGraph = styled(LineGraph)`
  height: 100%;
  max-height: 105px;

  .lineGraph__graphWrapper {
    height: 42px;
    margin-bottom: 20px;
  }

  @media (max-width: 714px) {
    .lineGraph__graphWrapper {
      height: 55px;
    }
  }
`;

const StyledCompetitorsLineGraph = styled(LineGraph)`
  height: 70px;
  margin-top: 5px;
  padding: 0;

  .lineGraph__graphWrapper {
    height: 50px;
    margin-bottom: 20px;
  }
  .lineGraph__numberMarkers {
    padding-bottom: 0px;
    width: 100%;
  }
  @media (max-width: 714px) {
    .lineGraph__graphWrapper {
      height: 55px;
    }
  }
`;

const MobileGraphs = styled.div`
  width: 240px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
`;

export const EvaluationGraphsStakeholder = ({
  isMobile,
  showDistribution,
  competitors,
  group,
  groups2,
  selectedRegion,
  stakeHolders,
  stakeholderDefinitionId,
  overallAverageScore,
  scoreDistribution,
  setDistributionModalRatings,
  rowRefs,
  isLead,
}: {
  isMobile: boolean;
  isLead: boolean;
  showDistribution: boolean;
  competitors: CompetitorFragment[];
  group: PostItGroupFragment;
  groups2: PostItGroupFragment[];
  stakeHolders: any;
  selectedRegion: string;
  stakeholderDefinitionId: any;
  overallAverageScore: number;
  scoreDistribution: Record<number, number>;
  setDistributionModalRatings({
    title,
    ratings,
  }: DistributionModalValues): void;
  rowRefs?: Record<number, React.RefObject<HTMLDivElement>>;
}) => {
  const mobileGraphsRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState('0px');

  const [scrollContainerMetrics, setScrollContainerMetrics] = useState({
    scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
    offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
    scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
  });

  useLayoutEffect(() => {
    if (showDistribution && isMobile) {
      setScrollContainerMetrics({
        scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
        offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
        scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
      });

      const throttledScrollHandler = throttle(
        (e) => {
          setScrollContainerMetrics({
            scrollWidth: mobileGraphsRef.current?.scrollWidth || 0,
            offsetWidth: mobileGraphsRef.current?.offsetWidth || 0,
            scrollLeft: mobileGraphsRef.current?.scrollLeft || 0,
          });
        },
        300,
        { trailing: true, leading: false }
      );

      if (mobileGraphsRef.current)
        mobileGraphsRef.current.addEventListener(
          'scroll',
          throttledScrollHandler
        );
      window.addEventListener('resize', throttledScrollHandler);
      const element = mobileGraphsRef.current;

      return () => {
        element?.removeEventListener('scroll', throttledScrollHandler);
        window?.removeEventListener('resize', throttledScrollHandler);
      };
    }
  }, [showDistribution, isMobile]);

  useLayoutEffect(() => {
    //Update row heights to match title rows
    if (rowRefs) {
      setHeight(Number(rowRefs[group.id]?.current?.offsetHeight) + 'px');
    }
  }, [rowRefs, group]);

  const handleScrollerClick = useCallback(
    (scrollDirection: 'left' | 'right') => {
      const mobileGraphs = document.getElementsByClassName(
        MOBILE_GRAPHS_CLASSNAME
      )?.[0];

      mobileGraphs?.scrollBy({
        left: scrollDirection === 'left' ? -240 : 240,
        top: 0,
        behavior: isSafari() ? 'auto' : 'smooth',
      });
    },
    []
  );

  if (isMobile) {
    return (
      <>
        {showDistribution ? (
          <div style={{ display: 'flex', gap: 2 }}>
            <GraphScroller
              // Left scroll
              hide={() => {
                const scrolledPastStart =
                  scrollContainerMetrics.scrollLeft === 0;

                return scrolledPastStart;
              }}
              onClick={() => handleScrollerClick('left')}
            />

            <MobileGraphs
              className={MOBILE_GRAPHS_CLASSNAME}
              ref={mobileGraphsRef}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 2,
                }}
              >
                {competitors.map((competitor) => (
                  //Competitor Headers - Mobile
                  <CompetitorTitleContainer
                    key={competitor.id}
                    style={{ height: 52 }}
                  >
                    <CompetitorTitleWrapper>
                      {competitor.image ? (
                        <CompetitorImage imageURL={competitor.image} />
                      ) : (
                        <TreatmentIcon />
                      )}
                      <CompetitorTitle>{competitor.title}</CompetitorTitle>
                    </CompetitorTitleWrapper>
                  </CompetitorTitleContainer>
                ))}
              </div>

              <CompetitorGraphsScrollWrapper className="competitorGraphsScrollWrapper">
                {competitors.map((competitor) => {
                  const competitiveLandscapeRating = group?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];

                  const competitorRatings = competitiveLandscapeRating.filter(
                    (rating) => rating.competitorId === competitor.id
                  );

                  const scoreDistribution = createScoreDistribution(
                    competitorRatings
                  );

                  const averageScore = average(
                    competitorRatings.map((rating) => rating.score)
                  ).toFixed(1);

                  return (
                    <CompetitorsGraphAreaWrapper
                      key={competitor.id}
                      isMobile={isMobile}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >aw
                        <ScorePill value={+averageScore}>
                          {averageScore ? (
                            <>
                              <BodySmall
                                style={{ marginRight: 3 }}
                                color={colors.greyDark}
                              >
                                Av.
                              </BodySmall>
                              <BodySmall color={colors.black}>
                                {'' + averageScore}
                              </BodySmall>
                            </>
                          ) : (
                            <BodySmall color={colors.greyDark}>
                              No ratings
                            </BodySmall>
                          )}
                        </ScorePill>
                        {!!isLead && (
                          <ChartButton
                            onClick={() => {
                              setDistributionModalRatings({
                                title: competitor.title,
                                ratings: competitorRatings,
                              });
                            }}
                          />
                        )}
                      </div>

                      <StyledCompetitorsLineGraph
                        showWeakStrongLabel={false}
                        values={scoreDistribution}
                        maxRating={5}
                      />
                    </CompetitorsGraphAreaWrapper>
                  );
                })}
              </CompetitorGraphsScrollWrapper>
            </MobileGraphs>
            <GraphScroller
              hide={() => {
                const roomToScroll =
                  scrollContainerMetrics.scrollWidth >=
                  scrollContainerMetrics.offsetWidth;

                const scrolledToEnd =
                  scrollContainerMetrics.scrollLeft ===
                  scrollContainerMetrics.scrollWidth -
                    scrollContainerMetrics.offsetWidth;

                return roomToScroll && scrolledToEnd;
              }}
              right
              onClick={() => handleScrollerClick('right')}
            />
          </div>
        ) : (
          <GraphAreaWrapper>
            <ScorePill value={overallAverageScore} />

            <StyledLineGraph
              showWeakStrongLabel={!isMobile}
              values={scoreDistribution}
              maxRating={5}
            />
          </GraphAreaWrapper>
        )}
      </>
    );
  }

  return showDistribution ? (
    <CompetitorGraphsScrollWrapper height={height}>
      {stakeHolders.map((competitor: any) => {
       const filteredGroups = groups2.filter(g => g.title === group.title && g.stakeholder === competitor.type);
       let allRatings2: CompetitiveLandscapeRatingFragment[] = [];

       const normalizeString = (str: string) => {
        return str
          .toLowerCase()              // Convert to lowercase
          .replace(/-/g, ' ')          // Replace hyphens with spaces
          .trim();                     // Remove leading/trailing spaces
      };
       // Iterate over each filtered group to collect ratings
       filteredGroups.forEach(filteredGroup => {
         const competitiveLandscapeRating2 = filteredGroup?.competitiveLandscapeRating as CompetitiveLandscapeRatingFragment[];
       
         // Filter ratings based on stakeholderDefinitionId and add them to the allRatings array
         const ratings = competitiveLandscapeRating2.filter(
          (rating) => rating.stakeholderDefinitionId === stakeholderDefinitionId
          && (selectedRegion === 'global' || rating.region === normalizeString(selectedRegion))
        );

         allRatings2 = [...allRatings2, ...ratings]; // Append ratings to the allRatings array
       });

       const averageScore = average(
         allRatings2.map((val) => val.score)
       ).toFixed(1);

       const scoreDistribution = createScoreDistribution(allRatings2);

        return (
          <CompetitorsGraphAreaWrapper key={competitor.id} isMobile={isMobile}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ScorePill value={+averageScore}>
                {averageScore ? (
                  <>
                    <BodySmall
                      style={{ marginRight: 3 }}
                      color={colors.greyDark}
                    >
                      Av.
                    </BodySmall>
                    <BodySmall color={colors.black}>
                      {'' + averageScore}
                    </BodySmall>
                  </>
                ) : (
                  <BodySmall color={colors.greyDark}>No ratings</BodySmall>
                )}
              </ScorePill>
              {!!isLead && (
                <ChartButton
                  onClick={() => {
                    setDistributionModalRatings({
                      title: competitor.title,
                      ratings: allRatings2,
                    });
                  }}
                />
              )}
            </div>

            <StyledCompetitorsLineGraph
              showWeakStrongLabel={false}
              values={scoreDistribution}
              maxRating={5}
            />
          </CompetitorsGraphAreaWrapper>
        );
      })}
      {/* Remaining White space */}
      <div
        className="graph__white-space"
        style={{
          width: '100%',
          height: '100%',
          flex: 1,
          borderRadius: '0 5px 5px 0',
          background: colors.white,
        }}
      />
    </CompetitorGraphsScrollWrapper>
  ) : (
    <GraphAreaWrapper height={height}>
      {isMobile && (
        <ScorePill value={overallAverageScore}>
          {overallAverageScore ? (
            <>
              <BodySmall style={{ marginRight: 3 }} color={colors.greyDark}>
                Av.
              </BodySmall>
              <BodySmall color={colors.black}>
                {'' + overallAverageScore}
              </BodySmall>
            </>
          ) : (
            <BodySmall color={colors.greyDark}>No ratings</BodySmall>
          )}
        </ScorePill>
      )}
      <StyledLineGraph
        showWeakStrongLabel={!isMobile}
        values={scoreDistribution}
        maxRating={5}
      />
    </GraphAreaWrapper>
  );
};
