import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link, useLocation, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import apolloClient from 'data/apollo';
import { BodySmall, ButtonRound, Caption, Icon, Subtitle2 } from './shared';
import buildStrategyURL from 'utils/buildStrategyURL';
import { zIndex } from 'constants/index';
import { ExcludedFeature } from 'types';
import { useDrugsQuery, useSignOutMutation } from 'data/graphql/generated';
import getInitials from 'utils/getInitials';
import { NotificationBell } from './notifications/NotificationBell';
import { HelpIcon } from 'components/HelpIcon';
import { checkIfFullNmblr } from 'containers/Strategies';

const TermsAndPolicies = styled(Subtitle2)`
  position: absolute;
  bottom: 92px;
  left: 30px;
  display: flex;
  color: ${colors.cream};
  cursor: pointer;
  pointer-events: all;

  a {
    color: inherit;
  }
`;

const ProfileIConWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  position: absolute;
  bottom: 145px;
  left: 30px;
  cursor: pointer;
  pointer-events: all;
`;

const Mobile = styled.div`
  display: block;
  @media screen and (min-width: 945px) {
    display: none;
  }
`;

const Content = styled.div`
  background: ${colors.black};
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  text-align: center;
  z-index: ${zIndex.nav};
`;

const Logo = styled.div`
  height: 50px;
  display: grid;
  place-content: center;
  position: absolute;
  left: 50%;
  width: 50px;
  transform: translateX(-50%);
  a {
    display: inline-block;
    vertical-align: top;
  }
`;

const SidebarTrigger = styled.div`
  height: 50px;
  display: grid;
  place-content: center;
  padding: 0px 10px;
  vertical-align: top;
  float: left;
  cursor: pointer;
`;

const SideMenu = styled.nav<{ visible: boolean }>`
  background: ${colors.black};
  position: fixed;
  top: 50px;
  left: 0px;
  bottom: 0px;
  width: 300px;
  overflow: hidden;
  text-align: left;
  transform: translate(${({ visible }) => (visible ? 0 : '-100%')}, 0);
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.navSideMenu};
`;

const SideMenuBG = styled.div<{ visible: boolean }>`
  background: ${colors.black};
  opacity: 0.95;
  position: fixed;
  top: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: left;
  z-index: ${zIndex.navSideMenuBG};
  transform: translate(${({ visible }) => (visible ? 0 : '-100%')}, 0);
  transition: transform 0.3s;
  cursor: pointer;
`;

const NavItems = styled.div<{ dim: boolean }>`
  padding-bottom: 4px;
  ${({ dim }) => {
    if (dim)
      return css`
        filter: brightness(0.5);
        pointer-events: none;
      `;
  }}
`;

const NavItem = styled.div<{ active: boolean; left: number }>`
  padding: 15px 10px;
  position: relative;
  background: ${({ active }) => (active ? colors.purple : 'transparent')};

  a {
    display: flex;
    align-items: center;
  }
  p {
    padding-left: 15px;
  }
`;

const CurrentlyViewing = styled.div`
  position: relative;
`;

const Signout = styled.div`
  position: absolute;
  bottom: 32px;
  left: 30px;
  display: flex;
  gap: 28px;
  flex-direction: column;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  padding: 15px 23px;
`;

const NavMidWrapper = styled.div<{ hideBorder: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  border-top: ${({ hideBorder }) =>
    hideBorder ? 'none' : `1px solid #ffffff`};
`;

interface Props {
  title?: string;
  drugId: string;
  strategyId: string;
  navMenuChildren?: React.ReactNode | null;
  exclude?: ExcludedFeature[];
  showNotifications(): void;
  notificationsVisible: boolean;
  notificationsUnread: number;
  showAccountModal(): void;
  stepNumber?: string;
  mobileNavRef?: React.RefObject<HTMLDivElement>;
}

export const NavbarMobile: React.FC<Props> = ({
  title,
  children,
  drugId,
  strategyId,
  navMenuChildren,
  exclude,
  showNotifications,
  notificationsVisible,
  notificationsUnread,
  showAccountModal,
  stepNumber,
  mobileNavRef,
}) => {
  const [auth, setAuth] = useAuthContext();
  const [signOut] = useSignOutMutation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const strategyHome = buildStrategyURL(drugId, strategyId, '');
  const peoplePage = buildStrategyURL(drugId, strategyId, 'people');
  const welcomeHome = buildStrategyURL(drugId, strategyId, 'welcome');
  // const filesPage = buildStrategyURL(drugId, strategyId, 'files');
  const onStrategyBubblesPage = !!pathname.match(/strategy\/\d+\/?$/g);
  const onHomePage = !!pathname.match(/^\/$/g);
  const onStrategyWizard = !!pathname.match(/\/strategy\/\d+\/create\//);
  const onCollaboratorsPage = !!pathname.match(/\/strategy\/\d+\/people/g);
  const hideNavContent =
    onStrategyBubblesPage || exclude?.includes('navContent');
  const showHelpIcon =
    !onStrategyBubblesPage &&
    !onHomePage &&
    !onStrategyWizard &&
    !onCollaboratorsPage;

  const showCurrentlyViewing =
    !onHomePage && !onStrategyWizard && !onStrategyBubblesPage;

  const initials = getInitials(auth?.user?.name);

  const { data: drugData } = useDrugsQuery({
    variables: {
      where: {
        id: Number(drugId),
      },
    },
    fetchPolicy: 'network-only',
  });

  const possibleDrugs = drugData?.drugs?.items;

  const miniFeatureSet = useMemo(() => {
    if (possibleDrugs) {
      return possibleDrugs.filter(i => i.id === Number(drugId)).flatMap((p: any) =>
        p.ProductOnDrugs?.flatMap((f: any) =>
          f.Product?.features?.map((g: any) => g.featureSet)
        )
      );
    }
    return [];
  }, [drugId, possibleDrugs]);

  const isFullNmblr = checkIfFullNmblr(miniFeatureSet);

  return (
    <Mobile>
      <Content ref={mobileNavRef} className="main__mobileNav">
        {exclude?.includes('sidebarTrigger') ? null : (
          <SidebarTrigger onClick={() => setDropdownVisible(!dropdownVisible)}>
            <Icon name="Menu" color={colors.cream} size={30} />
          </SidebarTrigger>
        )}
        <Logo>
          <Link to="/">
            <Icon name="Home" color={colors.cream} size={30} />
          </Link>
        </Logo>
        {exclude?.includes('notifications') ? null : (
          <div style={{ marginRight: 10 }}>
            <NotificationBell
              onClick={showNotifications}
              visible={notificationsVisible}
              notificationsUnread={notificationsUnread}
            />
          </div>
        )}
      </Content>
      <>
        <SideMenu visible={dropdownVisible}>
          {exclude?.includes('navItems') ? null : (
            <NavItems dim={onHomePage}>
              <NavItem
                active={onStrategyBubblesPage}
                left={32}
                onClick={() => setDropdownVisible(false)}
              >
              <Link to={drugId && strategyId && isFullNmblr ? strategyHome : drugId && strategyId && !isFullNmblr ? welcomeHome : '/'}>
                  <Icon name="Strategy" color={colors.cream} size={30} />
                  <Subtitle2 color={colors.cream}>Strategy</Subtitle2>
                </Link>
              </NavItem>
              {/* <NavItem
                active={pathname.includes('/files')}
                left={38}
                onClick={() => setDropdownVisible(false)}
              >
                <Link to={drugId && strategyId ? filesPage : '/'}>
                  <Icon name="Files" color={colors.cream} size={30} />
                  <Subtitle2 color={colors.cream}>Files</Subtitle2>
                </Link>
              </NavItem> */}
              <NavItem
                active={pathname.includes('/people')}
                left={36}
                onClick={() => setDropdownVisible(false)}
              >
                <Link to={drugId && strategyId ? peoplePage : '/'}>
                  <Icon name="People" color={colors.cream} size={30} />
                  <Subtitle2 color={colors.cream}>Collaborators</Subtitle2>
                </Link>
              </NavItem>
            </NavItems>
          )}

          {hideNavContent ? null : (
            <NavMidWrapper hideBorder={onCollaboratorsPage}>
              <>
                {onCollaboratorsPage ? null : showCurrentlyViewing ? (
                  <CurrentlyViewing>
                    <Caption color={colors.white}>Currently viewing:</Caption>
                    <Subtitle2 color={colors.white}>{title}</Subtitle2>
                    {navMenuChildren ? (
                      <div style={{ padding: '20px 0' }}>{navMenuChildren}</div>
                    ) : (
                      <div style={{ padding: '10px 0' }}></div>
                    )}
                  </CurrentlyViewing>
                ) : (
                  <BodySmall color={colors.greyMedium}>
                    You can navigate a strategy you are viewing from here.
                  </BodySmall>
                )}

                {showHelpIcon ? (
                  <HelpIcon
                    onClose={() => {
                      setDropdownVisible(false);
                    }}
                  />
                ) : null}
              </>
            </NavMidWrapper>
          )}
          <ProfileIConWrapper
            onClick={() => {
              showAccountModal();
              setDropdownVisible(false);
            }}
          >
            <ButtonRound text={initials} level="primary" size={40} />
            <Subtitle2 color={colors.cream}>Account</Subtitle2>
          </ProfileIConWrapper>
          <TermsAndPolicies>
            <a href="/terms/terms-of-service" target="_blank">
              Terms and Policies
            </a>
          </TermsAndPolicies>
          <Signout
            onClick={async () => {
              await signOut();
              setDropdownVisible(false);
              history.push('/signin');
              setAuth({
                loading: false,
                user: undefined,
              });
              apolloClient.clearStore();
              Sentry.configureScope((scope) => scope.setUser(null));
            }}
          >
            <Subtitle2 color={colors.cream}>Sign out</Subtitle2>
          </Signout>
        </SideMenu>

        <SideMenuBG
          onClick={() => setDropdownVisible(false)}
          visible={dropdownVisible}
        >
          <CloseBtn onClick={() => setDropdownVisible(false)}>
            <Icon name="Close" size={30} color={colors.cream} />
          </CloseBtn>
        </SideMenuBG>
      </>
    </Mobile>
  );
};
