import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Stakeholder } from 'data/graphql/generated';

import { device } from 'utils/breakpoints';
import { colors } from 'constants/colors';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { BodySmall } from 'components/shared';

const StyledPostItsEmpty = styled(PostItsEmpty)`
  @media ${device.tabletMin} {
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }

  @media ${device.mobile} {
    padding-bottom: 88px;
    height: auto;
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
  }
`;

interface Props {
  showEvaluation: boolean;
  hasNoSelectedCompetitor: boolean;
  hasStakeholderError: boolean;
  hasGroupsError: boolean;
  hasGroupItemsError: boolean;
  hasNoTargetPatientsError: boolean;
  hasStakeholderNotIdentifiedError: boolean;
  hasCompetitorError: boolean;

  isDesktop: boolean;
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
}

export const CompetitiveLandscapeErrors: React.FC<Props> = ({
  showEvaluation,
  hasNoTargetPatientsError,
  hasStakeholderNotIdentifiedError,
  hasNoSelectedCompetitor,
  hasStakeholderError,
  hasGroupsError,
  hasCompetitorError,
  isDesktop,
  hasGroupItemsError,
  drugId,
  strategyId,
  stakeholder,
}) => {
  if (hasNoTargetPatientsError) {
    return (
      <StyledPostItsEmpty
        style={{ marginTop: isDesktop ? 120 : 160 }}
        title="No Target Patients identified yet"
      >
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Only Patient Definitions starred against leverage points in{' '}
        </BodySmall>
        <Link
          to={`/d/${drugId}/strategy/${strategyId}/2_2`}
          style={{
            color: colors.greyDark,
            display: 'inline',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          2.2 Patient Flow{' '}
        </Link>
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          are available for consideration
        </BodySmall>
      </StyledPostItsEmpty>
    );
  }

  if (hasStakeholderNotIdentifiedError) {
    return (
      <StyledPostItsEmpty
        style={{ marginTop: isDesktop ? 120 : 160 }}
        title="This stakeholder has not been identified as relevant"
      >
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          To warrant further analysis, a stakeholder must be starred at the same
          stage as a leverage point in the{' '}
        </BodySmall>
        <Link
          to={`/d/${drugId}/strategy/${strategyId}/1_3/${stakeholder}`}
          style={{
            color: colors.greyDark,
            display: 'inline',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          1.3 Patient Journey
        </Link>
      </StyledPostItsEmpty>
    );
  }
  if (hasStakeholderError) {
    return (
      <StyledPostItsEmpty
        style={{ marginTop: isDesktop ? 120 : 160 }}
        title="Nothing to discuss for this stakeholder"
      >
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Work can only begin when a Lead has created the target patient population
        </BodySmall>
      </StyledPostItsEmpty>
    );
  }

  if (hasCompetitorError) {
    return (
      <StyledPostItsEmpty
        title="No competitors yet"
        subtitle={
          showEvaluation
            ? 'Use the “Evaluating” toggle above to go back and choose competitors before you evaluate the competitive landscape.'
            : 'Choose competitors to start assessing the competitive landscape.'
        }
      />
    );
  }

  if (hasNoSelectedCompetitor) {
    return (
      <StyledPostItsEmpty
        title="Select a competitor"
        subtitle={
          showEvaluation
            ? `Use the “Evaluating” toggle above to go back and select competitors for this stakeholder`
            : 'Select a competitor tab to begin evaluating.'
        }
      />
    );
  }

  if (hasGroupItemsError || hasGroupsError) {
    return (
      <StyledPostItsEmpty
        title="Nothing to discuss"
        subtitle={'Work can only begin when a Lead has chosen outcomes'}
      />
    );
  }

  // TODO: This code is commented out because of the modified code
  /* commented out because of the modified code
  if (hasGroupsError) {
    return (
      <StyledPostItsEmpty
        style={{
          marginTop: 0,
        }}
        title="Nothing to discuss for this stakeholder"
      >
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          {showEvaluation
            ? `Notes can only be added when this stakeholder has a note with an alignment under “What are the outcomes that matter to ${mapStakeholderEnumToPostItGroupTitle[stakeholder]}?”`
            : `Evaluation can only be done when this stakeholder has a note with an alignment under “What are the outcomes that matter to ${mapStakeholderEnumToPostItGroupTitle[stakeholder]}?”`}{' '}
          in{' '}
        </BodySmall>
        <Link
          to={`/d/${drugId}/strategy/${strategyId}/1_1/${stakeholder}`}
          style={{
            color: colors.greyDark,
            display: 'inline',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          1.1 Key Stakeholder Analysis.
        </Link>
      </StyledPostItsEmpty>
    );
  }
    */

  return null;
};
